import axios from 'axios';
import { Lambda, InvokeWithResponseStreamCommand } from "@aws-sdk/client-lambda"


class ConnAIsseurService {
    constructor() {
        this.baseUrl = 'https://kqc3wlbt4l.execute-api.us-west-1.amazonaws.com/dev';

        this.client = axios.create({
            baseUrl: this.baseURL,
            headers: {
                'x-api-key': 'JFuvuaqzky9Rozg7FZ9rF6cDQVWfApn71yGxMueH'
            },
        });
    }

    async askAWS(prompt) {
        const lambda = new Lambda({
            region: 'us-west-1',
            credentials: {
                accessKeyId: 'AKIAYHUJS4J4YUF4HBIH',
                secretAccessKey: '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'
            }
        });

        return lambda.send(new InvokeWithResponseStreamCommand(
            {
                FunctionName: 'streamAnswerQuery',
                Payload: JSON.stringify({ prompt })
            }
        ));
    }

    async streamCompletionLambda(prompt) {
        try {
            const response = await fetch(`https://yu7nwxe62lay6t3jt67fxjrfa40jmcfh.lambda-url.us-west-1.on.aws/`, {
                method: "POST",
                headers: {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify({
                    prompt
                })
            });

            return response.body;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async answerQueryWithContext(prompt, max_tokens, chat_history = []) {
        try {
            const response = await this.client.post(`${this.baseUrl}/askcnsr`, {
                prompt,
                max_tokens,
                chat_history
            });
            return response.data.body;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async answerQuery(prompt, max_tokens) {
        try {
            const response = await this.client.post(`${this.baseUrl}/askgpt`, {
                prompt,
                max_tokens
            });
            return response.data.body;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async streamCompletionOpenAI(prompt) {
        try {
            const response = await fetch(`https://api.openai.com/v1/completions`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer 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'
                },
                body: JSON.stringify({
                    model: "text-davinci-003",
                    prompt,
                    temperature: 0.75,
                    stream: true,
                    max_tokens: 50
                })
            });

            const reader = response.body.getReader();
            return reader;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getCopywrite(component, max_tokens) {
        try {
            const response = await this.client.post(`${this.baseUrl}/getcopywrite`, {
                component,
                max_tokens
            });
            return response.data.body;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async sendEmail(name, email, message) {
        try {
            const response = await this.client.post(`${this.baseUrl}/sendemail`, {
                name,
                email,
                message
            });
            return response.data.body;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async login(username, password) {
        try {
            // [CT] don't use this.client b/c we don't need Auth header
            const response = await axios.post(`${this.baseUrl}/login`, {
                username,
                password
            });
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

const connAIsseurService = new ConnAIsseurService();

export default connAIsseurService;
