import React from 'react'
import { Route, Routes } from 'react-router-dom'

import HomePage from './pages/HomePage/HomePage';
import Header from './components/Header/Header';

import './App.scss';

function App() {
  return (
    <>
      <div className="app-wrapper">
        <Header></Header>
        <div className='main-content'>
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
