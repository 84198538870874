import React, { useEffect, useState } from 'react';
import ConnAIsseurService from '../../services/ConnAIsseurService'
import { FaMobileAlt, FaEnvelope } from 'react-icons/fa'
import aiImage from '../../assets/images/aibot.png'

import './HomePage.scss';

function HomePage() {
    const [title, setTitle] = useState('');

    const [values, setValues] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');

    useEffect(() => {
        setTitle('');

        const streamDataSecured = async () => {
            try {
                const lambdaResponse = await ConnAIsseurService.askAWS("In less than 10 words. Write a witty, possitive coming-soon quote about a company that develops AI powered software solutions.");
                const decoder = new TextDecoder("utf-8");

                for await (const event of lambdaResponse.EventStream) {
                    const text = decoder.decode(event.PayloadChunk?.Payload);
                    setTitle(oldText => oldText + text.replaceAll('"', ''));
                }
            }
            catch (error) {
                console.log(error);
                throw error;
            }
        }

        streamDataSecured();
    }, [])

    function onChange(e) {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    function onBlur(e) {
        validation(e.target.name);
    }

    function validation(targetName) {
        let errors = {};

        if (targetName === 'name') {
            if (!values.name) {
                setNameError('name required');
            } else {
                setNameError('');
            }
        }

        if (targetName === 'email') {
            if (!values.email) {
                setEmailError('email required');
            } else if (!/\S+@\S+\.\S+/.test(values.email)) {
                setEmailError('email address is invalid');
            } else {
                setEmailError('');
            }
        }

        if (targetName === 'message') {
            if (!values.message) {
                setMessageError('message required')
            } else {
                setMessageError('');
            }
        }

        return errors;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (nameError || emailError || messageError) {
            return;
        } else {
            const response = await ConnAIsseurService.sendEmail(values.name, values.email, values.message);
            alert(`Thank you for your email. We'll get back to you as soon as possible!`);
            setValues({
                name: '',
                email: '',
                message: ''
            });
        }
    };

    return <>
        <article className='home-page-wrapper'>

            <section className='contact-form'>

                <form onSubmit={handleSubmit}>
                    
                    <section className='title-container'>
                        <div className='title'>
                            <h2>{title}</h2>
                        </div>
                        <div className='subtitle'><h3>In the meantime, get in touch using the contact form below.</h3></div>
                    </section>

                    <div className='label-for-input'>
                        <label htmlFor="name">Name</label>
                        {nameError ? <span> *{nameError}</span> : ''}
                    </div>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={values.name}
                        className={nameError ? 'error' : ''}
                        placeholder='name'
                        onChange={onChange}
                        onBlur={e => onBlur(e)}
                    />
                    <div className='label-for-input'>
                        <label htmlFor="email">Email</label>
                        {emailError ? <span> *{emailError}</span> : ''}
                    </div>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className={emailError ? 'error' : ''}
                        value={values.email}
                        placeholder='email'
                        onChange={onChange}
                        onBlur={e => onBlur(e)}
                    />
                    <div className='label-for-input'>
                        <label htmlFor="message">Message</label>
                        {messageError ? <span> *{messageError}</span> : ''}
                    </div>
                    <textarea
                        id="message"
                        name="message"
                        className={messageError ? 'error' : ''}
                        value={values.message}
                        placeholder='message'
                        onChange={onChange}
                        onBlur={e => onBlur(e)}
                    />
                    <button type="submit">Send</button>
                </form>
                <div className='contact-info'>
                    <h2>Contact Us</h2>
                    <div className='phone'>
                        <FaMobileAlt className='contact-icon' />
                        <span>(619)710-9350</span>
                    </div>
                    <div className='mail'>
                        <FaEnvelope className='contact-icon' />
                        <a href="mailto:contactus@conn-ai-sseur.com">contactus@conn-ai-sseur.com</a>
                    </div>
                </div>
                <div className='ai-image'>
                    <img src={aiImage} alt='ai-bot' />
                </div>
            </section>
        </article>
    </>;
}

export default HomePage;