import React from 'react';
import { useLocation } from 'react-router-dom';

import logo from '../../assets/images/logos/logo.png';

import './Header.scss';

function Header() {

    const location = useLocation();

    return <>
        <header className='header-wrapper'>
            <div className="logo">
                <img src={logo} alt="logo" />
            </div>
            <nav>
                <ul>
                    <li className={location.pathname === '/' ? 'selected' : ''}>
                        <a href="/">Home</a></li>
                </ul>
            </nav>
        </header>
    </>;
}

export default Header;